import BasePlugin from '../BasePlugin'

export default class AutomaticRoadMapCommand extends BasePlugin {
  async execute () {
    let applicationId = this.context.getModel().id

    this.context.$http.post(`${this.context.$config.api}/registryservice/plugins/execute/RegisterOfApplication/AutomaticRoadMapCommand`,
      { 'applicationId': applicationId },
      {
        headers: {
          'Content-Type': 'application/json'
        }
      }
    ).then((response) => {
      if (response.data === 'Records exists') {
        this.context.$msgbox({
          type: 'info',
          message: 'Дорожная карта сформирована. Повторное действие невозможно.'
        })
      }
      Object.values(this.context.getParentContext.getDashboardComponents()).forEach(function (item) {
        if (item[0].id === 2688) {
          item[0].loadData()
        }
      })
      done()
    })
  }
}
